<template>
    <div class="page-divider">
        <picture class="picture"
                 data-scroll
                 data-scroll-speed="-2"
        >
            <source
                media="(max-width:767px)"
                :srcset="item.mobile"
            />
            <source
                media="(max-width:1023px)"
                :srcset="item.tablet"
            />
            <img class="background-image"
                 :src="item.desktop"
            />
        </picture>
        <div class="content">
            <h2 class="header-title luxury">{{ title }}</h2>
            <div @click="changeRoute" class="explore-container">
                <svg width="38" height="10" class="icon" viewBox="0 0 38 10" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M31.8652 8.75479C31.5964 9.01868 31.5746 9.47032 31.8166
                           9.76354C32.0585 10.0568 32.4726 10.0805 32.7415 9.81663L37.6484 5L32.7415
                            0.183367C32.4726 -0.0805321 32.0585 -0.0567618 31.8166 0.236459C31.5746
                             0.529679 31.5964 0.981315 31.8652 1.24521L35.0279 4.34961H0.65C0.291015
                              4.34961 0 4.64062 0 4.99961C0 5.35859 0.291015 5.64961
                              0.65 5.64961H35.0287L31.8652 8.75479Z"
                          fill="#940128"></path>
                </svg>
                <span class="title fira">{{ item.urlTitle }}</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted} from "vue";
import {useRouter} from "vue-router";

const props = defineProps({
    contentData: {
        type: Object,
    },
});

let router = useRouter()

let title = computed(() => props.contentData?.conf?.header?.title)

let item = computed(() => {
    let item = props.contentData.data?.list?.[0]

    return {
        ...item,
        mobile: item?.images?.[0]?.devices?.mobile,
        desktop: item?.images?.[0]?.devices?.desktop,
        tablet: item?.images?.[0]?.devices?.tablet,
        urlTitle: item.url?.title,
        url: item.url?.url,
    }
})

const changeRoute = () => {
    if (item.value?.url?.includes('http')) {
        window.open(item.value?.url, '_blank');
        return
    }
    router.push({path: item.value?.url})
}

</script>

<style lang="scss" scoped>
$ease: cubic-bezier(.11, 0, .21, .98);
.page-divider {
    color: white;
    margin-top: 89px;
    height: 610px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    @media only screen and (max-width: 1650px) and (min-width: 1440px) {
        height: 550px;
    }
    @media only screen and (max-width: 1439px) and (min-width: 1024px) {
        height: 450px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        height: 400px;
    }
    @media only screen and (max-width: 767px) {
        height: 300px;
    }

    .picture {
        position: absolute;
        width: 100%;
        height: 120%;
    }

    .background-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .45s $ease;
    }

    .content {
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .header-title {
        font-weight: 400;
        font-size: 55px;
        line-height: 52px;
        text-align: center;
        text-transform: uppercase;
        @media only screen and (max-width: 1439px) {
            font-size: 34px;
        }
    }

    .explore-container {
        display: flex;
        align-items: center;
        margin-top: 19px;
        cursor: pointer;

        .icon {
            margin-right: 15px;
        }

        .title {
            font-weight: 700;
            font-size: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }
    }

}
</style>
<style lang="scss">
body {
    &.is-dark {
        .page-divider {

            .background-image {
                opacity: .6;
            }
        }
    }
}

</style>
